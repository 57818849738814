import React, { useEffect, useState } from "react";
import logo from "../../assets/images/logo.svg";
import { Link } from "react-router-dom";
import { getRequest, postRequest } from "../../utils/api";
import { useConnectWallet } from "@web3-onboard/react";
import Swal from "sweetalert2";

const Loading = () => (
  <div className="loader bg-white p-2 rounded-full flex space-x-2">
    <div className="w-3 h-3 bg-blue-500 rounded-full animate-bounce"></div>
    <div className="w-3 h-3 bg-blue-500 rounded-full animate-bounce"></div>
    <div className="w-3 h-3 bg-blue-500 rounded-full animate-bounce"></div>
  </div>
);

export default function Sidebar({ showDrawer, setShowDrawer, autoCompound, setAutoCompound }) {
  const [page, setPage] = useState("");
  const [{ wallet, connecting }] = useConnectWallet();
  const [laoding, setLoading] = useState(true);

  const toggleSwitch = async () => {
    const { isConfirmed, isDismissed, isDenied } = await Swal.fire({
      title: "Change Auto-compound status",
      text: "Do you want to change the auto-compound status?",
      icon: "question",
      showConfirmButton: true,
      showCancelButton: true,
    });
    if (isConfirmed) {
      const sig = localStorage.getItem("sig");
      if (wallet && sig) {
        try {
          await postRequest("/users/updatecompound", {
            wallet: wallet.accounts[0].address,
            signature: sig.toString(),
            status: !autoCompound,
          });
          setAutoCompound(!autoCompound);
        } catch (e) {
          Swal.fire("Change Auto-compound status", e, "error");
        }
      } else {
        Swal.fire("Change Auto-compound status", "Reconnect your wallet and try again.", "warning");
      }
    }
  };

  useEffect(() => {
    const loc = window.location;
    setPage(loc.pathname.replace("/", ""));
    setTimeout(async () => {
      setLoading(true);
      const sig = localStorage.getItem("sig");
      console.log({
        wallet,
        sig,
      });
      if (wallet && sig) {
        try {
          const result = await postRequest("/users/getUserData", {
            wallet: wallet.accounts[0].address,
            signature: sig.toString(),
          });
          setAutoCompound(result.data.autoCompound);
        } catch {
          setAutoCompound(false);
        }
        setLoading(false);
      } else {
        setLoading(false);
      }
    }, 500);
  }, [wallet]);

  return (
    <>
      <nav id="sidebarDash" className={` ${showDrawer && "active"}`}>
        <div className="sidebar-header shrink-0">
          <div className="logo">
            <img src={logo} alt="" />
          </div>
          <button type="button" className="btn-close-sidebar" onClick={() => setShowDrawer(false)}>
            <i className="fi fi-ss-cross-small"></i>
          </button>
        </div>
        <ul className="list-nav flex-auto">
          <li className={`nav-item ${page === "" ? "active" : ""}`}>
            <Link className="sub-item" to="/">
              <span className="icon">
                <i className="fi fi-rs-apps"></i>
              </span>
              <span className="title">Dashboard</span>
            </Link>
          </li>
          <li className={`nav-item ${page === "rewards" ? "active" : ""}`}>
            <Link className="sub-item" to="/rewards">
              <span className="icon">
                <i className="fi fi-rs-window-maximize"></i>
              </span>
              <span className="title">Holders Rewards</span>
            </Link>
          </li>
        </ul>
        {/* <div className="box p-3 shrink-0">
          <div className="border border-white/20 rounded-lg p-2">
            <label className="flex items-center cursor-pointer">
              <div className="relative">
                {laoding ? (
                  <Loading />
                ) : (
                  <>
                    {/* Switch 
                    <input
                      type="checkbox"
                      className="sr-only"
                      onChange={toggleSwitch}
                    />
                    <div
                      className={`block bg-gray-600 w-14 h-8 rounded-full ${
                        autoCompound ? "bg-green-500" : ""
                      }`}
                    ></div>
                    <div
                      className={`dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition ${
                        autoCompound ? "transform translate-x-6" : ""
                      }`}
                    ></div>
                  </>
                )}
              </div>
              /* Label 
              <div className="ml-3 text-white-700 font-medium">
                Auto-compound
              </div>
            </label>
          </div>
        </div> */}
      </nav>
      <div className={`sidebar-overlay ${showDrawer && "active"}`} onClick={() => setShowDrawer(false)}></div>
    </>
  );
}
