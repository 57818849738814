import React, { useEffect, useState } from "react";

import { useConnectWallet, useWallets } from "@web3-onboard/react";
import { postRequest } from "../../utils/api";
import Swal from "sweetalert2";

export default function Navbar({
  showDrawer,
  setShowDrawer,
  setWallet,
  walletForRewards,
  handleCallData,
}) {
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
  const connectedWallets = useWallets();
  const [walletAddress, setWalletAddress] = useState();
  const [user_address, setUserAddress] = useState("");

  const [page, setPage] = useState("");
  useEffect(() => {
    const loc = window.location;
    setPage(loc.pathname.replace("/", ""));
  }, []);
  let signature;

  let accounts;
  const handleConnect = async () => {
    if (wallet) {
      await disconnect(wallet);
      return;
    }

    const wallets = await connect();

    if (wallets) {
      try {
        window.provider = wallets[0].provider;
        accounts = await wallets[0].provider.request({
          method: "eth_requestAccounts",
        });

        const account = accounts[0];
        setUserAddress(account);
        window.user_address = account;
        const message = `Verify wallet ownership to view and claim Sect revenue share rewards.`;
        signature = await wallets[0].provider.request({
          method: "personal_sign",
          params: [message, account],
        });
        const addr = account;
        setWalletAddress(addr.substr(0, 4) + "..." + addr.substr(37, 5));

        await postRequest("/users/new", {
          wallet: addr,
          signature,
        });
        localStorage.setItem("sig", signature);
        Swal.fire("Authorization", "Authorized", "success");
      } catch (e) {
        Swal.fire("Authorization", e, "error");
        console.log(e);
      }
    }
  };
  useEffect(() => {
    if (connectedWallets.length > 0) {
      const addr = connectedWallets[0].accounts[0].address;
      setWalletAddress(addr.substr(0, 4) + "..." + addr.substr(37, 5));
    }
  }, [connectedWallets]);

  return (
    <>
      <nav id="navbarDash" className={` ${showDrawer && "active"}`}>
        <div className="collapse-sidebar">
          <button
            type="button"
            id="sidebarCollapse"
            onClick={() => setShowDrawer(!showDrawer)}
          >
            <i className="fi fi-rs-bars-staggered"></i>
          </button>
        </div>
        <div className="user-menu-content ml-auto">
          {page === "rewards" ? (
            <>
              <input
                type="text"
                className="rounded-full text-black bg-white py-3 px-4 font-bold"
                placeholder={"Wallet Address"}
                onChange={(e) => setWallet(e.target.value.toLowerCase())}
                value={walletForRewards}
              />
              <button
                disabled={connecting}
                onClick={() => handleCallData()}
                className="bg-primary text-black rounded-full py-3 px-4 font-bold hover:bg-primaryDark"
              >
                Check Rewards
              </button>
            </>
          ) : (
            <>
              <button
                disabled={connecting}
                onClick={() => handleConnect()}
                className="bg-primary text-black rounded-full py-3 px-4 font-bold hover:bg-primaryDark"
              >
                {connecting ? "connecting" : wallet ? walletAddress : "Connect"}
              </button>
            </>
          )}
        </div>
      </nav>
    </>
  );
}
