// src/api.js
import axios from "axios";
import Swal from "sweetalert2";

const axiosInstance = axios.create({
  baseURL: "https://revshare-api.sectbot.com",

  // baseURL: "http://localhost:3000",

  headers: {
    "Content-Type": "application/json",
  },
});

// Handle errors
const handleError = (error) => {
  if (error.response.status === 401) {
    Swal.fire("Error", "Please reconnect your wallet and sign the message", "error");
  }
  // You can handle errors based on status code or error message
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    console.error("Backend returned status code: ", error.response.status);
    console.error("Response data: ", error.response.data);
  } else if (error.request) {
    // The request was made but no response was received
    console.error("No response received for the request: ", error.request);
  } else {
    // Something else caused the error
    console.error("Error setting up the request: ", error.message);
  }
};

// GET request
export const getRequest = async (url) => {
  try {
    const response = await axiosInstance.get(url);
    console.log(response.data);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

// POST request
export const postRequest = async (url, data) => {
  try {
    const response = await axiosInstance.post(url, data);
    return response.data;
  } catch (error) {
    handleError(error);
    throw error;
  }
};

// Add more methods (PUT, DELETE, etc.) as needed
