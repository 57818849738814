import React, { useEffect, useMemo, useState } from "react";
import Navbar from "../Home/Navbar";
import Sidebar from "../Home/Sidebar";
import Main from "../Home/Main";
import { getRequest } from "../../utils/api";

export default function Rewards() {
  const [showDrawer, setShowDrawer] = useState(false);
  const [wallet, setWallet] = useState("");

  const [totalRewards, setTotalRewards] = useState(0);
  const [unClaimedRewards, setUnCaliamedRewards] = useState(0);
  const [claimedRewards, setClaimedRewards] = useState(0);
  const [rewardsList, setRewardsList] = useState([]);

  const handleCallData = async () => {
    const data = await getRequest(`/rewards/${wallet}`);

    if (data) {
      const { rewards, totalClaimable, totalClaimed, totalRewards } = data;
      setClaimedRewards(parseFloat(totalClaimed));
      setTotalRewards(parseFloat(totalRewards));
      setUnCaliamedRewards(parseFloat(totalClaimable));

      setRewardsList(rewards);
    }
  };

  const RewardsListComponent = useMemo(() => {
    return rewardsList.map((data, index) => {
      const blockDate = new Date(data.date);
      const now = new Date();
      const diff = (now.getTime() - blockDate.getTime()) / 1000;

      const unLockDate = new Date(data.date);
      unLockDate.setDate(unLockDate.getDate() + 1);
      let status = diff < 86400 ? "LOCK" : "Claimable";
      let statusClass =
        status === "LOCK" ? "text-yellow-500" : "text-white-500";
      if (data.forfeiture) {
        status = "Penalized";
        statusClass = "text-red-500";
      }
      return {
        blockNumber: data.blockNumber,
        date: blockDate.toLocaleString(),
        amountInEth: parseFloat(data.amountInEth).toFixed(5),
        blockDate: unLockDate.toLocaleString(), // Convert to a readable date string
        status,
        statusClass,
      };
    });
  }, [rewardsList]);
  return (
    <>
      <div className="wrapper">
        <Navbar
          showDrawer={showDrawer}
          setShowDrawer={setShowDrawer}
          setWallet={setWallet}
          walletForRewards={wallet}
          handleCallData={handleCallData}
        />
        <div id="contentDash" className={` ${showDrawer && "active"}`}>
          <Sidebar showDrawer={showDrawer} setShowDrawer={setShowDrawer} />
          <Main>
            <div className="my-5 border-t border-white/25 py-5">
              <h1 className="title text-[26px] font-bold mb-3">
                Your Rewards History
              </h1>
              <h6 className="mt-5 mb-3">Holder Rewards</h6>
              <div className="flex items-center justify-center flex-wrap">
                <div className="lg:w-1/3 md:w-1/2 w-full flex-grow px-2 mb-3">
                  <div className="border border-primary rounded-lg p-4">
                    <h6>Total Rewards</h6>
                    <div className="mt-2">{totalRewards.toFixed(5)} ETH</div>
                  </div>
                </div>
                <div className="lg:w-1/3 md:w-1/2 w-full flex-grow px-2 mb-3">
                  <div className="border border-primary rounded-lg p-4">
                    <h6>Unclaimed Rewards</h6>
                    <div className="mt-2">
                      {unClaimedRewards.toFixed(5)} ETH
                    </div>
                  </div>
                </div>
                <div className="lg:w-1/3 md:w-1/2 w-full flex-grow px-2 mb-3">
                  <div className="border border-primary rounded-lg p-4">
                    <h6>Claimed Rewards</h6>
                    <div className="mt-2">{claimedRewards.toFixed(5)} ETH</div>
                  </div>
                </div>
              </div>
              <div className="my-10">
                <h6 className="mb-4">Holder Rewards History</h6>
                <table className="table-custom">
                  <thead>
                    <tr>
                      <th>Snapshot Block</th>
                      <th>Time</th>
                      <th>Rewards</th>
                      <th>Unlock</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {RewardsListComponent.map((item, index) => (
                      <tr key={index}>
                        <th>{item.blockNumber}</th>
                        <th>{item.date}</th>
                        <th>{item.amountInEth}</th>
                        <th>{item.blockDate}</th>
                        <th className={item.statusClass}>{item.status}</th>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Main>
        </div>
      </div>
    </>
  );
}
