import React, { useState } from "react";
import Navbar from "../Home/Navbar";
import Sidebar from "../Home/Sidebar";
import Main from "../Home/Main";

export default function Referral() {
  const [showDrawer, setShowDrawer] = useState(false);
  return (
    <div className="wrapper">
      <Navbar showDrawer={showDrawer} setShowDrawer={setShowDrawer} />
      <div id="contentDash" className={` ${showDrawer && "active"}`}>
        <Sidebar showDrawer={showDrawer} setShowDrawer={setShowDrawer} />
        <Main>
          <h1 className="title text-[26px] font-bold mb-3">Referral Rewards</h1>
          <p>
            Referral codes are written into every swap transaction and rewards
            data is processed directly from the blockchain. Enter the referral
            code's reward recipient address to check rewards. Rewards data
            updates every 20 minutes.
          </p>
          <div className="flex items-center justify-center flex-wrap">
            <div className="lg:w-1/4 md:w-1/3 sm:w-1/2 w-full flex-grow px-2 mb-3">
              <div className="border border-primary rounded-lg p-4">
                <h6>Total Referrals</h6>
                <div className="mt-2">0 ETH</div>
              </div>
            </div>
            <div className="lg:w-1/4 md:w-1/3 sm:w-1/2 w-full flex-grow px-2 mb-3">
              <div className="border border-primary rounded-lg p-4">
                <h6>Transactions</h6>
                <div className="mt-2">0 ETH</div>
              </div>
            </div>
            <div className="lg:w-1/4 md:w-1/3 sm:w-1/2 w-full flex-grow px-2 mb-3">
              <div className="border border-primary rounded-lg p-4">
                <h6>Total Rewards</h6>
                <div className="mt-2">0 ETH</div>
              </div>
            </div>
            <div className="lg:w-1/4 md:w-1/3 sm:w-1/2 w-full flex-grow px-2 mb-3">
              <div className="border border-primary rounded-lg p-4">
                <h6>Claimable Rewards</h6>
                <div className="mt-2">0 ETH</div>
              </div>
            </div>
          </div>
          <div className="my-10">
            <h6 className="mb-4">Holder Rewards History</h6>
            <table className="table-custom">
              <thead>
                <tr>
                  <th>Snapshot Block</th>
                  <th>Time</th>
                  <th>Rewards</th>
                  <th>Unlock</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>8/5/2040</td>
                  <td>$2,300</td>
                  <td>$2,300</td>
                  <td>$2,300</td>
                  <td className="text-red-500">Error</td>
                </tr>
                <tr>
                  <td>8/5/2040</td>
                  <td>$2,300</td>
                  <td>$2,300</td>
                  <td>$2,300</td>
                  <td className="text-green-500">Success</td>
                </tr>
                <tr>
                  <td>8/5/2040</td>
                  <td>$2,300</td>
                  <td>$2,300</td>
                  <td>$2,300</td>
                  <td className="text-amber-500">Waiting</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Main>
      </div>
    </div>
  );
}
