import { useState, useEffect } from "react";
import { ethers } from "ethers";
import { TokenAddress, chainsData } from "../utils/consts";
import { useWallets } from "@web3-onboard/react";

const useTokenBalance = (
  tokenAddress = TokenAddress,
  providerUrl = chainsData[0].https
) => {
  const wallets = useWallets();
  const [balance, setBalance] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getBalance = async () => {
      console.log({ wallets });
      if (wallets.length === 0) return [balance, error];
      try {
        // Initialize the provider
        const provider = new ethers.providers.JsonRpcProvider(providerUrl);

        // Create a new instance of the Contract with the token ABI and address
        const tokenContract = new ethers.Contract(
          tokenAddress,
          ["function balanceOf(address owner) view returns (uint256)"],
          provider
        );

        const balanceBigNumber = await tokenContract.balanceOf(
          wallets[0].accounts[0].address
        );

        setBalance(ethers.utils.formatUnits(balanceBigNumber, "ether"));
      } catch (err) {
        setError(err.message);
      }
    };

    getBalance();
  }, [tokenAddress, wallets, providerUrl]);

  return { balance, error };
};

export default useTokenBalance;
