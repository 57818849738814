import RoutesList from "./Routes";

import { init } from "@web3-onboard/react";
import injectedModule from "@web3-onboard/injected-wallets";
import coinbaseModule from "@web3-onboard/coinbase";
import walletConnectModule from "@web3-onboard/walletconnect";
import Logo from "./assets/images/logo.svg";
const apiKey = "1730eff0-9d50-4382-a3fe-89f0d34a2070";
const injected = injectedModule();
const coinbase = coinbaseModule();

const wcInitOptions = {
  /**
   * Project ID associated with [WalletConnect account](https://cloud.walletconnect.com)
   */
  projectId: "1ce2544b47a56ecb3934fec0a1b64194",
  /**
   * Chains required to be supported by all wallets connecting to your DApp
   */
  requiredChains: [1],
  /**
   * Chains required to be supported by all wallets connecting to your DApp
   */
  /**
   * Defaults to `appMetadata.explore` that is supplied to the web3-onboard init
   * Strongly recommended to provide atleast one URL as it is required by some wallets (i.e. MetaMask)
   * To connect with WalletConnect
   */
  dappUrl: "http://sectbot.com",
};
const walletconnect = walletConnectModule(wcInitOptions);

const infuraKey = "10f04fb3e0004cd2bc571e7ae99593a9";
const rpcUrl = `https://mainnet.infura.io/v3/${infuraKey}`;

const appMetadata = {
  name: "Sect",
  icon: Logo,
  logo: Logo,
  description: "SECT",
  recommendedInjectedWallets: [
    { name: "MetaMask", url: "https://metamask.io" },
    // { name: 'Coinbase', url: 'https://wallet.coinbase.com/' }
  ],
  // agreement: {
  //   version: "1.0.1",
  //   termsUrl: "https://sectbot.com",
  // },
};
// initialize Onboard
init({
  apiKey,
  wallets: [injected, coinbase, walletconnect],
  theme: "dark",
  // accountCenter: { enabled: false },

  accountCenter: {
    desktop: {
      position: "topRight",
      enabled: false,
      minimal: true,
    },
    mobile: {
      position: "topRight",
      enabled: false,
      minimal: true,
    },
  },
  connect: {
    autoConnectLastWallet: true,
  },
  chains: [
    {
      id: "0x1",
      token: "ETH",
      label: "Ethereum Mainnet",
      rpcUrl,
    },
  ],
  appMetadata,
});

function App() {
  return (
    <>
      <RoutesList />
    </>
  );
}

export default App;
