import fetch from "axios";

export async function getTokenData(tokenAddress) {
  const url = `https://api.dexscreener.com/latest/dex/tokens/${tokenAddress}`;
  const response = await fetch.get(url);
  if (response.status !== 200) {
    return null;
  }
  const data = await response.data;
  console.log(data)
  return data;
}
