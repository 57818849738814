import React from "react";

export default function Main({ children }) {
  return (
    <>
      <div id="mainDash" className="main-body-content">
        <div className="main-header bg-transparent">{children}</div>
      </div>
    </>
  );
}
