import React, { useCallback, useEffect, useMemo, useState } from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Main from "./Main";
import useTokenBalance from "../../hooks/UseTokenBalance";
import { TokenAddress } from "../../utils/consts";
import { formatNumber } from "../../utils/format";
import { getRequest, postRequest } from "../../utils/api";
import { useWallets } from "@web3-onboard/react";
import Swal from "sweetalert2";
import { getTokenData } from "../../utils/dexScreener";

export default function Home() {
  const [showDrawer, setShowDrawer] = useState(false);
  const wallets = useWallets();
  const { balance, error } = useTokenBalance(TokenAddress);
  const [totalRewards, setTotalRewards] = useState(0);
  const [unClaimedRewards, setUnCaliamedRewards] = useState(0);
  const [claimedRewards, setClaimedRewards] = useState(0);
  const [tokenRewards, setTokenRewards] = useState(0);
  const [rewardsList, setRewardsList] = useState([]);
  const [autoCompound, setAutoCompound] = useState(false);
  const [autoCompoundAmount, setAutoCompoundAmount] = useState(false);

  useEffect(() => {
    (async () => {
      if (wallets.length === 0) return;
      const addr = wallets[0].accounts[0].address.toLowerCase();
      const data = await getRequest(`/rewards/${addr}`);

      if (data) {
        const { rewards, totalClaimable, totalClaimed, totalRewards, totalUnClaimedTokens } = data;
        setClaimedRewards(parseFloat(totalClaimed));
        setTotalRewards(parseFloat(totalRewards));
        setUnCaliamedRewards(parseFloat(totalClaimable));
        setTokenRewards(parseFloat(totalUnClaimedTokens).toFixed(1));

        setRewardsList(rewards);
      }
    })();
  }, [wallets]);

  useEffect(() => {
    (async () => {
      if (autoCompound === true) {
        const getTokenPrice = await getTokenData(TokenAddress);
        const priceNative = parseFloat(getTokenPrice.pairs[0].priceNative);
        console.log({ priceNative, unClaimedRewards });
        const getTokensAmount = parseFloat(unClaimedRewards) / priceNative;
        setAutoCompoundAmount(getTokensAmount.toFixed(1));
      }
    })();
  }, [autoCompound]);
  const RewardsListComponent = useMemo(() => {
    return rewardsList.map((data, index) => {
      const blockDate = new Date(data.date);
      const now = new Date();
      const diff = (now.getTime() - blockDate.getTime()) / 1000;

      const unLockDate = new Date(data.date);
      unLockDate.setDate(unLockDate.getDate() + 1);
      let status = diff < 86400 ? "LOCK" : "Claimable";
      let statusClass = status === "LOCK" ? "text-yellow-500" : "text-white-500";
      if (data.forfeiture) {
        status = "Penalized";
        statusClass = "text-red-500";
      }
      return {
        blockNumber: data.blockNumber,
        date: blockDate.toLocaleString(),
        amountInEth: parseFloat(data.amountInEth).toFixed(5),
        blockDate: unLockDate.toLocaleString(), // Convert to a readable date string
        status,
        statusClass,
      };
    });
  }, [rewardsList]);
  const handleShowAutocompoundMessage = async () => {
    const sectBalance = parseFloat(tokenRewards);
    const ethBalance = parseFloat(unClaimedRewards);
    const tokenPrice = await getTokenData(TokenAddress);
    const priceNative = parseFloat(tokenPrice.pairs[0].priceNative);
    const ethAmount = parseFloat(sectBalance) * priceNative;

    if (ethBalance + ethAmount < 0.1) {
      Swal.fire("Eligibility Alert", "To claim rewards, your accumulated ETH must exceed 0.1 ETH.", "warning");
      return;
    }
    // if (!autoCompound) {
    //   Swal.fire({
    //     title: "Auto Compound Notification",
    //     text: "To claim your rewards, please activate the auto-compound feature. This can be found at the bottom of the left-hand side slider.",
    //     icon: "warning",
    //   });
    //   return;
    // } else {
    handleShowMessage("SECT");
    //}
  };
  const handleShowMessage = async (token = "ETH") => {
    if (wallets.length === 0) {
      Swal.fire("Error", "Connect your wallet", "warning");
      return;
    }
    if (unClaimedRewards < 0.1 && token !== "SECT") {
      Swal.fire("Eligibility Alert", "To claim rewards, your accumulated ETH must exceed 0.1 ETH.", "warning");
      return;
    }
    try {
      const sig = localStorage.getItem("sig");

      if (sig) {
        await postRequest("/requests/newrequest", {
          wallet: wallets[0].accounts[0].address,
          signature: sig,
          token: token,
        });
        Swal.fire("Request Result", "Your request has been successfully sent.", "success");
        return;
      } else {
        Swal.fire("Error", "Please reconnect your wallet and sign the message", "error");
        return;
      }
    } catch (e) {
      Swal.fire("Error", e, "error");
    }
  };
  return (
    <>
      <div className="wrapper">
        <Navbar showDrawer={showDrawer} setShowDrawer={setShowDrawer} />
        <div id="contentDash" className={` ${showDrawer && "active"}`}>
          <Sidebar
            showDrawer={showDrawer}
            setShowDrawer={setShowDrawer}
            autoCompound={autoCompound}
            setAutoCompound={setAutoCompound}
          />
          <Main>
            <div className="flex items-start justify-center flex-wrap">
              <div className="lg:w-1/2 w-full px-2">
                <div className="p-2">
                  <h1 className="title text-[26px] font-bold mb-3">Revenue Share Dashboard</h1>
                  <p className="text-white text-justify">
                    Holders can connect using this dashboard to view and claim their revenue share rewards. A minimum
                    balance of 1000 $SECT is required. Claiming rewards claims all available unclaimed rewards and
                    requires a minimum of 0.1 ETH accumulated. To lookup revenue shares for other wallets, use the
                    Holder Rewards or Referral Rewards browser.{" "}
                  </p>
                </div>
                <div className="bg-amber-500/10 my-3 p-4 rounded-lg grid grid-cols-[48px,auto]">
                  <div className="w-12 h-12 rounded-full bg-amber-500 text-black text-[24px] flex items-center justify-center">
                    <i className="fi fi-rs-triangle-warning"></i>
                  </div>
                  <div className="pl-3">
                    <h6>Rewards Forfeiture</h6>
                    <p className="mb-0 mt-3 text-justify">
                      If you sell or transfer over 3000 $SECT, all unclaimed rewards will be forfeited back into the
                      rewards pool. If you sell your $SECT to a holding below 1000 $SECT your entire accrued rev share
                      will be forfeited.
                    </p>
                  </div>
                </div>
              </div>
              <div className="lg:w-1/2 w-full px-2">
                {/* <div className="border border-white/25 rounded-lg mb-3">
                  <div className="flex items-center justify-between border-b border-white/25 mb-3 p-3">
                    <h6 className="">Token Holding</h6>
                    <button
                      onClick={() => handleShowAutocompoundMessage()}
                      className="text-primary border border-primary rounded-full py-2 px-3 text-sm hover:bg-primary/20"
                    >
                      Claim $SECT
                    </button>
                  </div>
                  <div className="p-3">
                    <div className="bg-primary/10 flex items-center justify-between rounded-lg p-3 mb-3">
                      <div className="font-bold text-md text-white/80">Wallet</div>
                      <div className="font-bold text-md text-white">{formatNumber(balance)}</div>
                    </div>
                    {/deqw <div className="bg-primary/10 flex items-center justify-between rounded-lg p-3 mb-3">
                      <div className="font-bold text-md text-white/80">AUTO-COMPOUND</div>
                      <div className="font-bold text-md text-white">{formatNumber(autoCompoundAmount)}</div>
                    </div> 
                    <div className="bg-primary/10 flex items-center justify-between rounded-lg p-3 mb-3">
                      <div className="font-bold text-md text-white/80">SECT Rewards</div>
                      <div className="font-bold text-md text-white">{formatNumber(tokenRewards)}</div>
                    </div>
                    <p className="text-sm text-center">
                      Both wallet and auto-compound holdings are used to calculate your share of rewards.
                    </p>
                  </div>
                </div> */}
                <div className="border border-white/25 rounded-lg">
                  <div className="flex items-center justify-between border-b border-white/25 mb-3 p-3">
                    <h6 className="">Claimed Rewards</h6>
                    <button
                      onClick={() => handleShowMessage()}
                      className="text-primary border border-primary rounded-full py-2 px-3 text-sm hover:bg-primary/20"
                    >
                      Claim Rewards
                    </button>
                  </div>
                  <div className="p-3">
                    <table className="table-custom table-mini">
                      <thead>
                        <tr>
                          <th>Time</th>
                          <th>Amount</th>
                          <th>Transaction</th>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="my-5 border-t border-white/25 py-5">
              <h1 className="title text-[26px] font-bold mb-3">Your Rewards History</h1>
              <h6 className="mt-5 mb-3">Holder Rewards</h6>
              <div className="flex items-center justify-center flex-wrap">
                <div className="lg:w-1/3 md:w-1/2 w-full flex-grow px-2 mb-3">
                  <div className="border border-primary rounded-lg p-4">
                    <h6>Total Rewards</h6>
                    <div className="mt-2">{totalRewards.toFixed(5)} ETH</div>
                  </div>
                </div>
                <div className="lg:w-1/3 md:w-1/2 w-full flex-grow px-2 mb-3">
                  <div className="border border-primary rounded-lg p-4">
                    <h6>Unclaimed Rewards</h6>
                    <div className="mt-2">{unClaimedRewards.toFixed(5)} ETH</div>
                  </div>
                </div>
                <div className="lg:w-1/3 md:w-1/2 w-full flex-grow px-2 mb-3">
                  <div className="border border-primary rounded-lg p-4">
                    <h6>Claimed Rewards</h6>
                    <div className="mt-2">{claimedRewards.toFixed(5)} ETH</div>
                  </div>
                </div>
              </div>
              <div className="my-10">
                <h6 className="mb-4">Holder Rewards History</h6>
                <table className="table-custom">
                  <thead>
                    <tr>
                      <th>Snapshot Block</th>
                      <th>Time</th>
                      <th>Rewards</th>
                      <th>Unlock</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {RewardsListComponent.map((item, index) => (
                      <tr key={index}>
                        <th>{item.blockNumber}</th>
                        <th>{item.date}</th>
                        <th>{item.amountInEth}</th>
                        <th>{item.blockDate}</th>
                        <th className={item.statusClass}>{item.status}</th>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Main>
        </div>
      </div>
    </>
  );
}
